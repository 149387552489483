import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useContext, useState } from "react";
import '../styles/checkoutform.css';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import { BASE_URL } from "../Adapters";

const CheckoutForm = ({ setShowCheckPage, shippingFormValues, cartTotalPrice }) => {
  const { addToCart, setAddToCart } = useContext(AppContext);
  const navigate = useNavigate()

  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    console.log(event, "event")
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };
  console.log(shippingFormValues, "values")
  const handleOrderSubmit = async () => {
    try {
      // event.preventDefault();
      // const data = new FormData(event.currentTarget);
      // const values = Object.fromEntries(data.entries());
      const values = shippingFormValues;
      // Create a new FormData object
      const formData = new FormData();

      // Append values to FormData
      formData.append('printful_order_id', values?.printful_order_id);

      const response = await axios.post(
        `${BASE_URL}product/api/order/confrim`,
        formData
      );
      // navigate('/check_page');
      // setShowCheckPage(true)
      console.log({ response });
    } catch (error) {
      console.log(error);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    if (email) {
      setPaymentLoader(true)
      // event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        alert('wait for stripe');
        return;
      }



      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });


      try {
        const response = await axios.post(
          `${BASE_URL}product/api/payments/`,
          {
            email: email,
            payment_method_id: paymentMethod.id,
            total_price: cartTotalPrice
          }
        );


        const { paymentIntent, confirmError, error } = await stripe.confirmCardPayment(response.data?.client_secret, {
          payment_method: paymentMethod.id,
        });

        if (confirmError?.message) {
          toast.error(confirmError?.message);
        }

        if (error?.message) {
          toast.error(error?.message);
        }

        if (paymentIntent.status == "succeeded") {
          handleOrderSubmit()
          setPaymentConfirmed(true);
          toast.success("Payment Successfull");
          setAddToCart([])
          navigate("/")
        }
        setPaymentLoader(false)
      } catch (err) {
        setPaymentLoader(false)
      }
    }
  };

  return (
    !paymentConfirmed ?
      <div className="stripe-form">
        <div className="form-row">
          <label htmlFor="email">Email Address</label>
          <input
            className="form-input"
            id="email"
            name="name"
            type="email"
            placeholder="jenny.rosen@example.com"
            required
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="card-element">Credit or debit card</label>
          <CardElement id="card-element" onChange={handleChange} />
          <div className="card-errors" role="alert">
            {error}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 mb-2 mb-md-0">
            <button className="cancel-btn" onClick={() => { setShowCheckPage(false) }}>
              Cancel
            </button>
          </div>
          <div className="col-sm-12 col-md-6">
            <button className="submit-btn" type="button" onClick={() => { handleSubmit() }} disabled={paymentLoader || !email}>
              Submit Payment
            </button>
          </div>
        </div>
      </div> :
      <div className="my-5">
        <h1 className="text-success text-center">
          Payment Has Been Processed Successfully.
        </h1>
      </div>

  );
};


const StripePaymentWrapper = ({ setShowCheckPage, shippingFormValues, cartTotalPrice }) => {
  return (
    <Elements
      // "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"
      // "pk_test_51PD4CFKMnegld3GupBxgQmRCHj51JKFVoTrfMmrnHzGTKqHQBXbceTpW0foqgHu9U1tAPr30vMPGkL00e0unFwbo00HscTlKWS"
      stripe={loadStripe(
        "pk_live_51NtJL7EnZdzS3h5B5kTNUQTRadShpXnybNvGg8h3FfsnOl0R8G2t5fkBeSLw15bndcYpPPtzHwaxLZ5ww7j6ED8800U57wxHJX"
      )}

    >
      <CheckoutForm setShowCheckPage={setShowCheckPage} shippingFormValues={shippingFormValues} cartTotalPrice={cartTotalPrice} />
    </Elements>
  );
};

export default StripePaymentWrapper;